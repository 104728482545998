<template>
  <div class="payment">
    <div class="left">
      <el-radio-group @change="valueChange()" v-model="product" class="mode">
        <el-radio-button label="Membership">購買會籍<i class="ms-2 fa-regular fa-user-large"></i></el-radio-button>
        <el-radio-button label="LineBotPoint">購買LineBot點數<i class="ms-2 fa-regular fa-money-bill-simple"></i></el-radio-button>
      </el-radio-group>
      <p class="mt-3 infoBox">(購買後可至會員中心 → 付款資訊，查看所有購買紀錄)</p>
      <div class="main">
        <div class="choice" v-if="product == 'Membership'">
          <p>選擇等級</p>
          <el-radio-group @change="memberLevelChange()" v-model="memberLevel" class="mt-3">
            <el-radio label="Special">特仕</el-radio>
            <el-radio label="Pro">專業</el-radio>
          </el-radio-group>
        </div>
        <div class="choice">
          <p>選擇方案</p>
          <el-radio-group @change="valueChange()" v-for="item in Object.keys(productList)" :key="item" v-model="value[item]" v-show="item == product" class="mt-3">
            <el-radio :label="product.id" v-for="product in productList[item]" :key="product" v-show="product.memberLevel == memberLevel || product.type == 'LineBotPoint'">{{ product.name }}</el-radio>
          </el-radio-group>
        </div>
        <div class="choice">
          <p>付款方式</p>
          <el-radio-group v-model="payMethodValue" class="mt-3">
            <el-radio :label="item.type" v-for="item in paymentTypeList" :key="item">{{ item.name }}</el-radio>
          </el-radio-group>
        </div>
        <div class="chargeMePoint">
          <p>可使用的ChargeMe點數(最多折抵50%)：{{ chargeMePoint }} 點</p>
          <div class="d-flex mt-3 align-items-center">
            <el-input v-model="ruleForm.chargeMePoint" @input="ruleForm.chargeMePoint = turnNumber_Min_Max(ruleForm.chargeMePoint, 0, chargeMePoint > currentPrice * 0.5 ? currentPrice * 0.5 : chargeMePoint)"></el-input>
            <span class="ms-2">點</span>
          </div>
        </div>
      </div>
      <el-button v-if="!$store.getters.mobileSize" class="mt-3" size="large" type="primary" @click="pay()">前往付款<i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></el-button>
    </div>
    <el-form @submit.prevent :model="ruleForm" :rules="rules" ref="ruleForm" :label-position="labelPosition">
      <div class="info">
        <div>
          <p class="title">基本資料(開立發票用)</p>
          <el-checkbox v-model="isKeepInfo" label="記住本次基本資料" size="large" />
        </div>
        <div class="name">
          <label class="red_star">* <span>姓名</span></label>
          <el-form-item prop="name">
            <el-input v-model="ruleForm.name" placeholder="請輸入姓名" />
          </el-form-item>
        </div>
        <div class="email">
          <label class="red_star">* <span>Email</span></label>
          <el-form-item prop="email">
            <el-input v-model="ruleForm.email" placeholder="請輸入信箱" />
          </el-form-item>
        </div>
        <div class="address">
          <label class="red_star"><span>地址</span></label>
          <el-form-item prop="address">
            <el-input v-model="ruleForm.address" placeholder="請輸入地址" />
          </el-form-item>
        </div>
        <div class="UBN">
          <label class="red_star"><span>統一編號</span></label>
          <el-form-item prop="UBN">
            <el-input v-model="ruleForm.UBN" placeholder="請輸入統一編號" />
          </el-form-item>
        </div>
        <div class="carrierLove">
          <el-radio-group v-model="carrierLove" class="mb-3">
            <el-radio class="mt-3" label="none" border>不使用載具或捐贈碼</el-radio>
            <el-radio class="mt-3" label="carrier" border>載具</el-radio>
            <el-radio class="mt-3" label="loveCode" border>捐贈碼</el-radio>
          </el-radio-group>
          <div class="type">
            <div class="carrier" v-if="carrierLove == 'carrier'">
              <el-radio-group v-model="ruleForm.carrierType">
                <el-radio :label="'0'">手機條碼載具</el-radio>
                <el-radio :label="'1'">自然人憑證條碼載具</el-radio>
                <!-- <el-radio :label="2">ezPay 電子發票載具</el-radio> -->
              </el-radio-group>
              <el-form-item prop="carrierNum">
                <el-input v-model="ruleForm.carrierNum" placeholder="請輸入載具號碼" />
              </el-form-item>
            </div>
            <div class="loveCode" v-else-if="carrierLove == 'loveCode'">
              <el-form-item prop="loveCode">
                <el-input v-model="ruleForm.loveCode" placeholder="請輸入捐贈碼" />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <el-button v-if="$store.getters.mobileSize" class="mt-3 w-100" size="large" type="primary" @click="pay()">前往付款<i class="ms-2 fa-sharp fa-solid fa-arrow-right"></i></el-button>
  </div>
</template>

<script>
export default {
  name: "Payment",
  data() {
    var validateCarrierNum = (rule, value, callback) => {
      var regex = "";
      if (this.ruleForm.carrierType == "0") {
        regex = /^\/[0-9A-Z.\-+]{7}$/;
        if (!regex.test(value)) callback(new Error("請輸入正確手機條碼載具格式"));
        else callback();
      } else if (this.ruleForm.carrierType == "1") {
        regex = /^[A-Z]{2}\d{14}$/;
        if (!regex.test(value)) callback(new Error("請輸入正確自然人憑證條碼載具格式"));
        else callback();
      } else {
        callback();
      }
    };
    return {
      originData: [],
      product: "Membership",
      carrierLove: "none",
      isKeepInfo: true,
      value: {
        Membership: 0,
        LineBotPoint: 0,
      },
      productList: {},
      paymentTypeList: [],
      memberLevel: "Special",
      payMethodValue: "",
      labelPosition: "top",
      chargeMePoint: 0,
      ruleForm: {
        name: "",
        email: "",
        address: "",
        UBN: "",
        carrierType: "0",
        carrierNum: "",
        loveCode: "",
        chargeMePoint: 0,
      },
      rules: {
        name: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        email: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            type: "email",
            message: "請輸入正確信箱格式",
            trigger: ["blur", "change"],
          },
        ],
        UBN: [
          {
            pattern: /^[0-9]{8}$/,
            message: "請輸入正確統一編號格式",
            trigger: "change",
          },
        ],
        carrierNum: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          { validator: validateCarrierNum, trigger: "change" },
        ],
        loveCode: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
          {
            pattern: /^\d{3,7}$/,
            message: "請輸入正確捐贈碼格式",
            trigger: "change",
          },
        ],
      },
    };
  },
  async mounted() {
    const vm = this;
    this.log_PageInfo("MemberStore", "8");
    await vm.initData();
    await vm.GetMemberPurchaseInfo();
  },
  computed: {
    currentPrice() {
      const vm = this;
      var productId = 0;

      switch (vm.product) {
        case "Membership":
          productId = vm.value.Membership;
          break;
        case "LineBotPoint":
          productId = vm.value.LineBotPoint;
          break;
      }
      return vm.originData.productList.find(x => x.id == productId).amount;
    },
  },
  methods: {
    memberLevelChange() {
      const vm = this;
      vm.value.Membership = vm.productList["Membership"].find(x => x.memberLevel == vm.memberLevel).id;
    },
    valueChange() {
      const vm = this;
      if (vm.ruleForm.chargeMePoint > vm.currentPrice * 0.5) this.ruleForm.chargeMePoint = vm.currentPrice * 0.5;
    },
    initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Payment`)
        .then(res => {
          console.log(res.data);
          vm.originData = res.data;
          vm.productList = res.data.productList.reduce((groups, item) => {
            const type = item.type;
            if (!groups[type]) {
              groups[type] = [];
            }
            groups[type].push(item);
            return groups;
          }, {});
          vm.paymentTypeList = res.data.paymentTypeList;

          vm.value = {
            Membership: vm.productList["Membership"][0].id,
            LineBotPoint: vm.productList["LineBotPoint"][0].id,
          };
          vm.payMethodValue = vm.paymentTypeList[0].type;

          this.$nextTick(() => {
            if (this.$route.query.memberLevel != undefined) {
              this.memberLevel = this.$route.query.memberLevel;
              this.value.Membership = this.productList["Membership"].find(x => x.memberLevel == this.memberLevel).id;
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    },
    GetMemberPurchaseInfo() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Payment/MemberPurchaseInfo`)
        .then(res => {
          console.log(res.data);
          var info = res.data;
          vm.ruleForm.email = info.buyerEmail;
          vm.ruleForm.name = info.buyerName;
          vm.ruleForm.address = info.buyerAddress;
          vm.ruleForm.UBN = info.buyerUBN;
          vm.ruleForm.carrierNum = info.carrierNum;
          vm.ruleForm.carrierType = info.carrierType;
          vm.ruleForm.loveCode = info.loveCode;
          vm.chargeMePoint = info.chargeMePoint;

          if (vm.ruleForm.name == "") vm.ruleForm.name = vm.currentUser().name;
          if (vm.ruleForm.email == "") vm.ruleForm.email = vm.currentUser().email;
          if (vm.ruleForm.carrierType == "") vm.ruleForm.carrierType = "0";
        })
        .catch(err => {
          console.log(err);
        });
    },
    pay() {
      const vm = this;
      vm.$refs["ruleForm"].validate(async valid => {
        if (valid) {
          // 確認是否降級
          if (vm.product == "Membership" && vm.originData.productList.find(x => x.id == vm.value.Membership).memberLevel == "Special" && !(await vm.checkMemberDowngrade())) {
            vm.$confirm("親愛的會員，您目前為專業會員，如果購買特仕會員，將會降級並且不會補償任何價差，請確認是否繼續購買流程！", "通知: 專業會員降級為特仕會員", {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                vm.DoPayment();
              })
              .catch(() => {
                return;
              });
          } else {
            vm.DoPayment();
          }
        } else {
          vm.notify("warning", `請確認基本資料已填寫完成!`);
        }
      });
    },
    async DoPayment() {
      const vm = this;
      // 確認會籍開課上限
      var isMemberLevelUpgradeVerify = await vm.isMemberLevelUpgradeVerify(vm.originData.productList.find(x => x.id == vm.value.Membership).memberLevel);
      if (vm.product == "Membership" && isMemberLevelUpgradeVerify) {
        vm.$confirm("親愛的會員，您目前的開課堂數已超過購買等級的上限堂數，需要購買專業會員，或者維持開課堂數(課程選單+封存+垃圾桶)小於上限堂數，方可購買！", "開課堂數超過上限", {
          confirmButtonText: "前往課程選單",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/ChargeMeContactBook/ClassRoomCenter");
          })
          .catch(() => {
            return;
          });
      } else {
        var itemDesc = "";
        var productId = 0;
        var productItem;

        switch (vm.product) {
          case "Membership":
            productId = vm.value.Membership;
            productItem = vm.originData.productList.find(x => x.id == productId);
            itemDesc = `購買會籍-${productItem.memberLevel == "Pro" ? "專業" : "特仕"}-${productItem.name}`;
            break;
          case "LineBotPoint":
            productId = vm.value.LineBotPoint;
            itemDesc = `購買LineBot點數-${vm.originData.productList.find(x => x.id == productId).name}`;
            break;
        }

        var carrierNum = "";
        var carrierType = "";
        var loveCode = "";
        switch (vm.carrierLove) {
          case "none":
            break;
          case "carrier":
            carrierNum = vm.ruleForm.carrierNum;
            carrierType = vm.ruleForm.carrierType;
            break;
          case "loveCode":
            loveCode = vm.ruleForm.loveCode;
            break;
        }

        // 補價差
        var spreadAmount = 0;
        var spreadObj = {};
        if (vm.product == "Membership" && productItem.memberLevel == "Pro") {
          try {
            const res = await this.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Payment/GetSpread`);
            spreadObj = res.data;
            spreadAmount = res.data.spreadAmount;
          } catch (err) {
            console.log(err);
          }
        }

        var postData = {
          ChannelID: vm.payMethodValue,
          ItemDesc: itemDesc,
          Amt: vm.originData.productList.find(x => x.id == productId).amount + spreadAmount,
          ReturnURL: `${process.env.VUE_APP_URL_API}/payment/ReturnURL`,
          NotifyURL: `${process.env.VUE_APP_URL_API}/payment/NotifyURL`,
          CustomerURL: `${process.env.VUE_APP_URL_API}/payment/CustomerURL`,
          ClientBackURL: `${process.env.VUE_APP_CURRENT_DOMAIN}MemberCenter/Order`,
          Email: vm.ruleForm.email,
          ProductId: productId,
          BuyerName: vm.ruleForm.name,
          BuyerAddress: vm.ruleForm.address,
          BuyerUBN: vm.ruleForm.UBN,
          CarrierNum: carrierNum,
          CarrierType: carrierType,
          LoveCode: loveCode,
          isKeepInfo: vm.isKeepInfo,
          ChargeMePoint: vm.ruleForm.chargeMePoint == "" ? 0 : vm.ruleForm.chargeMePoint,
          SpreadAmount: spreadAmount,
        };

        if (spreadAmount != 0) {
          vm.$confirm(
            `親愛的會員，由於您從特仕升級為專業會員，需補足今日至會籍到期日( ${spreadObj.membershipExpiredDateTime.split("T")[0]} )的價差，
                          以一天 ${spreadObj.diffAmount} 元，共 ${spreadObj.diffDays} 天為計算，需補足價差為 ${spreadAmount} 元，加上此次購買的項目為 ${vm.originData.productList.find(x => x.id == productId).amount} 元，
                          因此總付款金額為 ${postData.Amt} 元，請繼續按下確認進行付款流程！`,
            `通知: 特仕升級為專業，需補價差 ${spreadAmount} 元`,
            {
              confirmButtonText: "確認",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Payment/SendToNewebPay`, postData)
                .then(res => {
                  var returnObj = res.data;
                  console.log(returnObj);
                  const form = document.createElement("form");
                  form.method = "post";
                  form.action = process.env.VUE_APP_URL_PAYMENT; //藍新金流驗證網址(測試環境)

                  for (const key in returnObj) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (returnObj.hasOwnProperty(key)) {
                      const hiddenField = document.createElement("input");
                      hiddenField.type = "hidden";
                      hiddenField.name = vm.capitalizeFirstLetter(key);
                      hiddenField.value = returnObj[key];

                      form.appendChild(hiddenField);
                    }
                  }
                  document.body.appendChild(form);
                  form.submit();
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(() => {
              return;
            });
        } else {
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Payment/SendToNewebPay`, postData)
            .then(res => {
              var returnObj = res.data;
              console.log(returnObj);
              const form = document.createElement("form");
              form.method = "post";
              form.action = process.env.VUE_APP_URL_PAYMENT; //藍新金流驗證網址(測試環境)

              for (const key in returnObj) {
                // eslint-disable-next-line no-prototype-builtins
                if (returnObj.hasOwnProperty(key)) {
                  const hiddenField = document.createElement("input");
                  hiddenField.type = "hidden";
                  hiddenField.name = vm.capitalizeFirstLetter(key);
                  hiddenField.value = returnObj[key];

                  form.appendChild(hiddenField);
                }
              }
              document.body.appendChild(form);
              form.submit();
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  width: 100%;
  padding: 1rem 2rem;
  margin: auto;
}
.main {
  margin-top: 2rem;
  .choice {
    text-align: start;
    margin-top: 1rem;
  }
  .chargeMePoint {
    text-align: start;
    margin-top: 2rem;
  }
  .red_star {
    color: red !important;
    text-align: start;
    span {
      font-size: 1.1rem;
      font-weight: 900;
      color: rgba(0, 0, 0, 0.55);
    }
  }
  p {
    font-weight: bold;
  }
}
.info {
  font-weight: bold;
  font-size: 0.9rem;
  border: 1px solid #c1c1c1;
  border-radius: 1rem;
  padding: 1rem;
  margin: 2rem 0 1rem;
  .title {
    font-size: 1.1rem;
    font-weight: bold;
  }
}
:deep .el-form {
  text-align: start !important;
}
.infoBox {
  font-weight: bold;
  font-size: 0.9rem;
}

@media screen and (min-width: 768px) {
  .payment {
    padding: 5rem;
    margin: auto;
    display: flex;
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
  }
  .left {
    margin-right: 5rem;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }
  .info {
    margin-top: 0;
  }
  .mode,
  .infoBox {
    align-self: flex-start;
  }
}
</style>
